var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action",class:{
    'action-selected': _vm.selected,
    'multi-select-cursor-allowed': _vm.shiftHover && !_vm.readOnly,
    'multi-select-cursor-not-allowed': _vm.shiftHover && _vm.readOnly,
    expanded: _vm.analyticsExpanded,
    'grab-mode': _vm.grabMode
  },style:([_vm.actionStyle, _vm.selectedActionStyles]),attrs:{"data-action-id":_vm.action.id},on:{"click":_vm.onClick,"mousedown":_vm.onMouseDown,"mouseover":_vm.onMouseOver,"mousemove":_vm.onMouseOver,"mouseleave":_vm.onMouseLeave}},[_c('action-new-ui',{attrs:{"action":_vm.action,"grab-mode":_vm.grabMode,"errored":_vm.errored},on:{"toggle-analytics":_vm.toggleAnalytics},scopedSlots:_vm._u([{key:"stats",fn:function(){return [_c(_vm.actionStat(_vm.action),_vm._b({tag:"component",staticClass:"action-stats",attrs:{"stats":_vm.action.stats}},'component',_vm.actionStatExtendedProps(_vm.action),false)),_vm._v(" "),_c('div',{staticClass:"view-more"},[_c('span',{on:{"click":_vm.showReporting}},[_vm._v(_vm._s(_vm.$t('actionViewMore')))])])]},proxy:true}])}),_vm._v(" "),(!_vm.dragging)?_c('action-controls',{attrs:{"show-controls":_vm.showControls && _vm.controlsAvailable,"read-only":_vm.readOnly,"workflow":_vm.workflow,"action":_vm.action,"grab-mode":_vm.grabMode,"selected-actions":_vm.selectedActions},on:{"mouseover":function($event){_vm.inControls = true},"mouseleave":function($event){_vm.inControls = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }