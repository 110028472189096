<template>
  <div>
    <toolbar-editor @save="save" @discard="discard"/>
    <div id="ckeditor-container"></div>
  </div>
</template>
<script>
import CKEditorLoader from '@/libs/CKEditorLoader'
import ToolbarEditor from './Builder/ToolbarEditor.vue'

export default {
  components: { ToolbarEditor },
  inject: ['workflowApi', 'contentApi'],
  props: ['action', 'onConfirmClose', 'messageContentId', 'onClose'],

  watch: {
    messageContentId: {
      immediate: true,
      handler(contentId) {
        if (contentId) {
          this.contentApi.load(contentId).then((data) => {
            this.setContent(data.contentId, data.content)
          })
        }
      }
    }
  },

  created() {
    this.loadEditor()
    window.addEventListener('resize', this.resizeEditor)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeEditor)

    this.save()
    this.editor && this.editor.destroy()
    this.editorContainer &&
      this.editorContainer.parentElement.removeChild(this.editorContainer)
  },

  activated() {
    this.editorContainer && this.editorContainer.classList.remove('deactivated')
    this.resizeEditor()

    if (this.editor) {
      document.body.style.pointerEvents = 'auto'
    }
  },

  deactivated() {
    this.save()
    this.editorContainer && this.editorContainer.classList.add('deactivated')
  },

  methods: {
    save(canClose) {
      if (this.contentId !== this.messageContentId) return
      if (!(this.editor && this.editor.checkDirty())) return

      this.contentApi
        .saveEditor(this.editor.getData())
        .then(({ contentId }) => {
          this.$emit('save', contentId)
        }).finally(() => {
          if (canClose) {
            this.$emit('close')
          }
        })
    },

    loadEditor() {
      if (this.editor) return

      CKEditorLoader.load().then((CKEditor) => {
        CKEditor.config.versionCheck = false
        const div = document.createElement('div')
        div.classList.add('editor-container')
        document.querySelector('#ckeditor-container').appendChild(div)
        this.editorContainer = div

        const editor = (this.editor = CKEditor.appendTo(div, {
          fullPage: true,
          enterMode: CKEditor.ENTER_BR,
          entities: false,
          allowedContent: true,
          removePlugins: 'about,elementspath,magicline',
          toolbar: [
            [
              'Source',
              '-',
              'Cut',
              'Copy',
              'Paste',
              'PasteText',
              '-',
              'Undo',
              'Redo',
              '-',
              'Bold',
              'Italic',
              'RemoveFormat',
              '-',
              'NumberedList',
              'BulletedList',
              'Outdent',
              'Indent',
              '-',
              'Link',
              'Unlink',
              '-',
              'Image',
              '-'
            ]
          ]
        }))

        editor.on('instanceReady', () => {
          document.body.style.pointerEvents = 'auto'
          this.resizeEditor()
          if (this.content) {
            this.initContent(this.content)
          }
        })
      })
    },

    setContent(contentId, content) {
      this.contentId = contentId
      this.content = content
      this.initContent(content)
    },

    initContent(content) {
      if (this.editor) {
        this.editor.setData(content, {
          callback: () => {
            this.editor.resetDirty()
            this.editor.focus()
          },
          noSnapshot: true
        })
      }
    },

    resizeEditor() {
      requestAnimationFrame(() => {
        try {
          const width = this.editorContainer.offsetWidth,
            height = this.editorContainer.offsetHeight

          this.editor && this.editor.resize(width, height, false)
        } catch (e) {}
      })
    },

    discard() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="sass">

.sidebar-body
  padding: 0

.editor-container
    z-index: 4
    position: absolute
    top: 55px
    right: 0
    width: 1200px
    bottom: 0
    overflow: hidden

    &.deactivated
        display: none

.cke_chrome
    border: 0 !important
</style>
