<template>
  <TabView @tab-change="generateKey">
    <TabPanel :header="$t('tabPanelSetup')">
      <div class="setup-tab-ct">
        <div class="label-input-group">
          <span class="new-ui-label">{{ $t('defaultLanguageLabel') }}:</span>
          <language-selector
            :value="config.defaultLang"
            @input="setDefaultLang"
            :disabled="!isAvailable"
          />
        </div>

        <div class="label-input-group">
          <span class="new-ui-label">
            {{ $t('additionalLanguagesLabel') }}:
          </span>
          <multi-language-selector
            :key="config.defaultLang"
            :value="additionalLanguages"
            @input="setLanguages"
            :disabled="!isAvailable"
            :default-language-code="config.defaultLang"
          />
        </div>
      </div>
    </TabPanel>
    <TabPanel :header="$t('tabPanelContent')">
      <!-- Content Section -->
      <div
        style="display: flex; justify-content: space-between; gap: 10px"
        :key="panelKeyState"
      >
        <div class="tab-ct">
          <TabView @tab-change="setPreviewLanguage" :key="getKey">
            <TabPanel
              style="padding-bottom: 0"
              v-for="(lang, index) in sortedLanguages"
              :header="getLanguage(lang)"
              :tab-index="index"
              :key="lang"
            >
              <div class="content-tab-ct">
                <div class="label-input-group">
                  <span class="new-ui-label">
                    {{ $t('webhookUrl') }}
                  </span>
                  <InputText
                    id="url"
                    v-model="config.messages[lang].url"
                    type="text"
                    class="form-input"
                    :placeholder="$t('webhookUrlPlaceholder')"
                    ref="url"
                    :disabled="readOnly"
                  />
                </div>
                <div class="label-input-group">
                  <span class="new-ui-label">
                    {{ $t('webhookGetParamLabel') }}
                  </span>
                  <div
                    v-for="(payloadValue, index) in config.messages[lang]
                      .get_params"
                    style="display: flex; align-items: center"
                    :key="index"
                  >
                    <InputText
                      v-model="payloadValue.key"
                      type="text"
                      style="flex: 1; margin-right: 20px"
                      :placeholder="$t('keyPlaceholderLabel')"
                      :disabled="readOnly"
                    />
                    <personalised-input
                      v-model="payloadValue.value"
                      type="text"
                      :profile-attributes="profileAttributes"
                      :placeholder="$t('valuePlaceholderLabel')"
                      :emoji-enabled="false"
                      :disabled="readOnly"
                      :event-properties-enabled="false"
                    />
                    <Button
                      class="close"
                      style="margin: 0 20px"
                      @click="removePayload(index)"
                      :disabled="readOnly"
                    >
                      ×
                    </Button>
                  </div>
                  <Button
                    class="p-button-outlined"
                    style="width: fit-content"
                    @click="addPayload"
                    :label="$t('webhookAddGetParamLabel')"
                    :disabled="readOnly || !isAddPayloadEnabled"
                  />
                </div>
                <div class="label-input-group">
                  <span class="new-ui-label">
                    {{ $t('webhookRequestBody') }}
                  </span>
                  <Dropdown
                    v-model="
                      config.messages[getCurrentLanguage].request_body_type
                    "
                    :options="requestBodyOptions"
                    option-label="name"
                    option-value="value"
                    :disabled="readOnly"
                  />
                </div>
                <div class="label-input-group" v-if="isRequestBodyRaw">
                  <span class="new-ui-label">
                    {{ $t('webhookRequestTypeRaw') }}
                  </span>
                  <personalised-input
                    v-model="
                      config.messages[getCurrentLanguage].request_body_data.raw
                    "
                    :disabled="readOnly"
                    type="textarea"
                    :emoji-enabled="false"
                    :event-properties-enabled="false"
                    :profile-attributes="profileAttributes"
                    rows="5"
                  />
                </div>
                <div class="label-input-group" v-if="isRequestBodyPost">
                  <span class="new-ui-label">
                    {{ $t('webhookRequestTypePost') }}
                  </span>
                  <div
                    v-for="(payloadValue, index) in config.messages[lang]
                      .request_body_data.post"
                    style="display: flex; align-items: center"
                    :key="index"
                  >
                    <InputText
                      v-model="payloadValue.key"
                      type="text"
                      style="flex: 1; margin-right: 20px"
                      :placeholder="$t('keyPlaceholderLabel')"
                      :disabled="readOnly"
                    />
                    <personalised-input
                      type="text"
                      v-model="payloadValue.value"
                      :profile-attributes="profileAttributes"
                      :emoji-enabled="false"
                      :event-properties-enabled="false"
                      :disabled="readOnly"
                      :placeholder="$t('valuePlaceholderLabel')"
                    />
                    <Button
                      class="close"
                      style="margin: 0 20px"
                      @click="removePost(index)"
                      :disabled="readOnly"
                    >
                      ×
                    </Button>
                  </div>
                  <Button
                    style="width: fit-content"
                    class="p-button-outlined"
                    @click="addPost"
                    :label="$t('webhookAddPostParamLabel')"
                    :disabled="readOnly || !isAddPostEnabled"
                  />
                </div>
                <div class="label-input-group" v-if="isRequestBodyJson">
                  <span class="new-ui-label">
                    {{ $t('webhookRequestTypeJson') }}
                  </span>
                  <div
                    v-for="(payloadValue, index) in config.messages[lang]
                      .request_body_data.json"
                    style="display: flex; align-items: center"
                    :index="index"
                  >
                    <InputText
                      v-model="payloadValue.key"
                      type="text"
                      style="flex: 1; margin-right: 20px"
                      :placeholder="$t('keyPlaceholderLabel')"
                      :disabled="readOnly"
                    />
                    <personalised-input
                      v-model="payloadValue.value"
                      type="text"
                      :profile-attributes="profileAttributes"
                      :placeholder="$t('valuePlaceholderLabel')"
                      :disabled="readOnly"
                      :emoji-enabled="false"
                      :event-properties-enabled="false"
                    />
                    <Button
                      class="close"
                      style="margin: 0 20px"
                      @click="removeJson(index)"
                      :disabled="readOnly"
                    >
                      ×
                    </Button>
                  </div>
                  <Button
                    style="width: fit-content"
                    class="p-button-outlined"
                    @click="addJson"
                    :label="$t('webhookAddJsonParamLabel')"
                    :disabled="readOnly || !isAddJsonEnabled"
                  />
                </div>
                <div class="label-input-group">
                  <span class="new-ui-label">
                    {{ $t('webhookRequestHeaders') }}
                  </span>
                  <div
                    v-for="(payloadValue, index) in config.messages[lang]
                      .request_headers"
                    style="display: flex; align-items: center"
                    :index="index"
                  >
                    <InputText
                      v-model="payloadValue.key"
                      type="text"
                      style="flex: 1; margin-right: 20px"
                      :placeholder="$t('keyPlaceholderLabel')"
                      :disabled="readOnly"
                    />
                    <personalised-input
                      v-model="payloadValue.value"
                      type="text"
                      :placeholder="$t('valuePlaceholderLabel')"
                      :profile-attributes="profileAttributes"
                      :emoji-enabled="false"
                      :disabled="readOnly"
                      :event-properties-enabled="false"
                    />
                    <Button
                      class="close"
                      style="margin: 0 20px"
                      @click="removeRequestHeader(index)"
                      :disabled="readOnly"
                    >
                      ×
                    </Button>
                  </div>
                  <Button
                    style="width: fit-content"
                    class="p-button-outlined"
                    @click="addRequestHeader"
                    :label="$t('webhookAddRequestParamLabel')"
                    :disabled="readOnly || !isAddRequestHeadersEnabled"
                  />
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
        <!-- Preview Section -->
        <div class="col-md-6">
          <div class="action-ct" style="text-align: right">
            <el-dropdown v-if="!readOnly" trigger="click">
              <div class="action-btn">
                <span>{{ $t('actions') }}</span>
                <icon
                  style="margin-bottom: 2px"
                  glyph="chevron-down"
                  size="14px"
                />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="setDialogVisible = true">
                    <i class="pi pi-file" />
                    {{ $t('applyTemplate') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <apply-template-dialog
              :visible="setDialogVisible"
              @setVisible="setDialogVisible = false"
              :channel="MessageTypes.WEBHOOK"
              :has-content="hasContent"
              @applyMessageTemplate="importTemplate"
            />
          </div>
          <div class="preview">
            <webhook-preview :message="getMessagePreview" />
          </div>
          <div class="template-summary" v-tooltip:top="formatTemplateTooltip">
            {{ formatTemplateInfo }}
          </div>
        </div>
      </div>
    </TabPanel>
  </TabView>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector'
import MultiLanguageSelector from '@/components/MultiLanguageSelector'
import ISO6391 from 'iso-639-1'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import ApplyTemplateDialog from '@/components/ApplyTemplateDialog'
import { MessageTypes } from './MessageTypesEnum'
import XpApi from '@/libs/XpApi'
import moment from 'moment/moment'
import InputText from 'primevue/inputtext'
import WebhookPreview from '@/components/WebhookPreview'
import Button from 'primevue/button'
import _ from 'lodash'
import Dropdown from 'primevue/dropdown'
import Textarea from 'primevue/textarea/Textarea'
import ActionDefaults from '@/libs/ActionDefaults'
import PersonalisedInput from '@/components/PersonalisedInput'

export default {
  name: 'Webhook',

  components: {
    PersonalisedInput,
    Dropdown,
    Button,
    WebhookPreview,
    InputText,
    ApplyTemplateDialog,
    LanguageSelector,
    MultiLanguageSelector,
    TabView,
    TabPanel,
    Textarea
  },

  props: ['action', 'readOnly', 'workflow'],

  inject: ['workflowApi'],

  data() {
    const config = Object.assign(
      {
        messages: {
          en: {
            url: '',
            request_body_data: {
              post: [],
              raw: '',
              json: []
            },
            get_params: [],
            request_headers: [],
            request_body_type: 'raw'
          }
        }
      },
      JSON.parse(JSON.stringify(this.action.config))
    )

    return {
      config,
      messagePreviewLanguage: '',
      panelKeyState: 1,
      MessageTypes,
      setDialogVisible: false
    }
  },

  created() {
    this.$store.dispatch('fetchAttributes')
  },

  beforeDestroy() {
    if (this.isAvailable) {
      for (const [key, value] of Object.entries(this.config.messages)) {
        switch (value.request_body_type) {
          case 'raw':
            this.config.messages[key].request_body_data.json = []
            this.config.messages[key].request_body_data.post = []
            break
          case 'json':
            this.config.messages[key].request_body_data.raw = ''
            this.config.messages[key].request_body_data.post = []
            break
          case 'post':
            this.config.messages[key].request_body_data.raw = ''
            this.config.messages[key].request_body_data.json = []
            break
        }
      }
      const config = JSON.parse(JSON.stringify(this.config))
      this.workflowApi.updateAction(this.action.id, { config })
    }
  },

  computed: {
    isRequestBodyRaw() {
      return this.config.messages[this.getCurrentLanguage].request_body_type === 'raw'
    },

    isRequestBodyJson() {
      return this.config.messages[this.getCurrentLanguage].request_body_type === 'json'
    },

    isRequestBodyPost() {
      return this.config.messages[this.getCurrentLanguage].request_body_type === 'post'
    },

    languages() {
      return this.config.languages
    },

    additionalLanguages() {
      return this.config.languages.filter(
        (lang) => lang !== this.config.defaultLang
      )
    },

    sortedLanguages() {
      return [this.config.defaultLang, ...this.additionalLanguages]
    },

    isAvailable() {
      return !this.readOnly && this.$store.getters.webhookEnabled
    },

    getKey() {
      // @todo review this issue, its not a good way to be using these keys
      // The prime vue tabs are not reactive between each tab click
      // If the default language changes or a language is removed it does is not reactive
      // so this key maintains reactivity
      return this.config.defaultLang + this.config.languages.length
    },

    hasContent() {
      return !_.isEqual(
        this.config.messages[this.getCurrentLanguage],
        ActionDefaults.Webhook.config.messages.en
      )
    },

    getCurrentLanguage() {
      return this.messagePreviewLanguage || this.config.defaultLang
    },

    formatTemplateTooltip() {
      const whoApplied =
        this.action.config.templateInfo?.[this.getCurrentLanguage]?.whoApplied
      if (!whoApplied) {
        return ''
      }

      const lastApplied = moment(
        this.action.config.templateInfo?.[this.getCurrentLanguage]?.lastApplied
      ).format('DD/MMM/YYYY HH:mm')

      return this.$t('templateAppliedBy', {
        whoApplied: whoApplied,
        lastApplied: lastApplied
      })
    },

    formatTemplateInfo() {
      const templateTitle =
        this.action.config.templateInfo?.[this.getCurrentLanguage]
          ?.templateTitle
      if (!templateTitle) {
        return ''
      }

      return this.$t('templateCreatedFrom') + templateTitle
    },

    isAddPayloadEnabled() {
      if (
        this.config.messages[this.getCurrentLanguage].get_params.length === 0
      ) {
        return true
      }

      let last = _.last(
        this.config.messages[this.getCurrentLanguage].get_params
      )
      return last.key !== '' && last.value !== ''
    },

    isAddPostEnabled() {
      if (
        this.config.messages[this.getCurrentLanguage].request_body_data.post
          .length === 0
      ) {
        return true
      }

      let last = _.last(
        this.config.messages[this.getCurrentLanguage].request_body_data.post
      )
      return last.key !== '' && last.value !== ''
    },

    isAddJsonEnabled() {
      if (
        this.config.messages[this.getCurrentLanguage].request_body_data.json
          .length === 0
      ) {
        return true
      }

      let last = _.last(
        this.config.messages[this.getCurrentLanguage].request_body_data.json
      )
      return last.key !== '' && last.value !== ''
    },

    isAddRequestHeadersEnabled() {
      if (
        this.config.messages[this.getCurrentLanguage].request_headers.length ===
        0
      ) {
        return true
      }

      let last = _.last(
        this.config.messages[this.getCurrentLanguage].request_headers
      )
      return last.key !== '' && last.value !== ''
    },

    requestBodyOptions() {
      return [
        {
          name: this.$t('webhookRequestTypeRaw'),
          value: 'raw'
        },
        {
          name: this.$t('webhookRequestTypePost'),
          value: 'post'
        },
        {
          name: this.$t('webhookRequestTypeJson'),
          value: 'json'
        }
      ]
    },

    getMessagePreview() {
      let message = {}

      const currentMessage = this.config.messages[this.getCurrentLanguage]

      message.url = `${currentMessage.url ? currentMessage.url : ''}${
        currentMessage.get_params.length > 0
          ? '?' + this.encodeParams(currentMessage.get_params)
          : ''
      }`

      message.headers = $.map(currentMessage.request_headers, (item) => {
        return `${item.key}: ${item.value}`
      }).join('<br>')

      switch (currentMessage.request_body_type) {
        case 'raw':
          message.body = currentMessage.request_body_data.raw
          break
        case 'json':
          message.body =
            '{' +
            currentMessage.request_body_data.json
              .map((item) => {
                return `"${item.key}":"${item.value}"`
              })
              .join() +
            '}'
          break
        case 'post':
          message.body = this.encodeParams(
            currentMessage.request_body_data.post
          )
          break
      }
      return message
    },

    profileAttributes() {
      let attributes = this.$store.state.project.attributes

      if (attributes.length === 0) {
        return [{}]
      }

      return this.$store.state.project.attributes
    }
  },

  methods: {
    setDefaultLang(lang) {
      if (!this.config.languages.includes(lang)) {
        this.config.languages.push(lang)
      }

      this.config.defaultLang = lang
    },

    setLanguages(languages) {
      if (!languages.includes(this.config.defaultLang)) {
        languages.push(this.config.defaultLang)
      }
      this.config.languages = languages
    },

    getLanguage(languageCode) {
      return ISO6391.getName(languageCode)
    },

    setPreviewLanguage({ index }) {
      this.messagePreviewLanguage = this.sortedLanguages[index]
    },

    generateKey() {
      // @todo review this issue, its not a good way to be using these keys
      // The prime vue tabs are not reactive between each tab click
      // If the default language changes or a language is removed it does is not reactive
      // so this key maintains reactivity
      // This will make sure we go to the first tab as that will always be the default language tab
      this.panelKeyState += this.panelKeyState
      this.messagePreviewLanguage = ''
    },

    importTemplate(template) {
      if (this.readOnly) {
        return
      }

      this.setDialogVisible = false
      const projectId = this.$store.state.app.project.id
      XpApi.get(`projects/${projectId}/campaign-templates/${template.id}`, {
        params: {
          fields: 'id,title',
          lang: this.getCurrentLanguage,
          expand: 'message'
        }
      }).then(({ data: template }) => {
        Object.assign(
          this.config.messages[this.getCurrentLanguage],
          template.message.webhook
        )

        if (
          this.config.messages[this.getCurrentLanguage].request_body_data
            .json === ''
        ) {
          this.config.messages[this.getCurrentLanguage].request_body_data.json =
            []
        }

        if (
          this.config.messages[this.getCurrentLanguage].request_body_data
            .post === ''
        ) {
          this.config.messages[this.getCurrentLanguage].request_body_data.post =
            []
        }

        this.config.templateInfo = this.config.templateInfo || {}
        this.config.templateInfo[this.getCurrentLanguage] = {
          templateId: template.id,
          templateTitle: template.title,
          whoApplied: this.$store.state.app.user.email,
          lastApplied: new Date().getTime()
        }

        const config = JSON.parse(JSON.stringify(this.config))
        this.workflowApi.updateAction(this.action.id, { config })
      })
    },

    addPayload() {
      this.config.messages[this.getCurrentLanguage].get_params.push({
        key: '',
        value: ''
      })
    },

    removePayload(index) {
      this.$delete(
        this.config.messages[this.getCurrentLanguage].get_params,
        index
      )
    },

    addPost() {
      this.config.messages[this.getCurrentLanguage].request_body_data.post.push(
        {
          key: '',
          value: ''
        }
      )
    },

    removePost(index) {
      this.$delete(
        this.config.messages[this.getCurrentLanguage].request_body_data.post,
        index
      )
    },

    addJson() {
      this.config.messages[this.getCurrentLanguage].request_body_data.json.push(
        {
          key: '',
          value: ''
        }
      )
    },

    removeJson(index) {
      this.$delete(
        this.config.messages[this.getCurrentLanguage].request_body_data.json,
        index
      )
    },

    addRequestHeader() {
      this.config.messages[this.getCurrentLanguage].request_headers.push({
        key: '',
        value: ''
      })
    },

    removeRequestHeader(index) {
      this.$delete(
        this.config.messages[this.getCurrentLanguage].request_headers,
        index
      )
    },

    encodeParams(parameters) {
      let params = []
      for (let param in parameters) {
        if (parameters[param].key && parameters[param].value) {
          params.push(`${parameters[param].key}=${parameters[param].value}`)
        }
      }

      return `${params.join('&')}`
    }
  },

  watch: {
    languages: {
      immediate: true,
      handler(languages) {
        languages.forEach((lang) => {
          if (!this.config.messages[lang]) {
            this.$set(this.config.messages, lang, {
              url: '',
              request_body_data: {
                post: [],
                raw: '',
                json: []
              },
              get_params: [],
              request_headers: [],
              request_body_type: 'raw'
            })
          }
        })

        Object.keys(this.config.messages).forEach((lang) => {
          if (!languages.includes(lang)) {
            this.$delete(this.config.messages, lang)
          }
        })
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.content
    overflow: scroll
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    padding: 20px 600px 20px 20px

    .language-header
        font-weight: bold
        font-size: 14px
        margin-top: 10px
        margin-bottom: 10px

.preview
    display: flex
    justify-content: center
    margin-top: 25px
    background: #ececec
    padding: 50px 50px 50px 50px
    height: fit-content

.setup-tab-ct
  display: flex
  flex-direction: column
  gap: 20px
  width: 50%

.content-tab-ct
  display: flex
  flex-direction: column
  gap: 20px

.tab-ct
  display: flex
  flex-direction: column
  gap: 20px
  width: 50%
  flex-grow: 1

.label-input-group
  display: flex
  flex-direction: column
  gap: 7px

.new-ui-label
  color: #4b515f
  font-weight: 600

.new-ui-label-width
  color: #4b515f
  font-weight: 600

.content-section-ct
  display: flex
  gap: 120px

.content-section-wrapper
  width: 50%
  display: flex
  flex-direction: column
  gap: 20px
  flex-grow: 1

.label-input-group
  display: flex
  flex-direction: column
  gap: 7px

.action-btn
  display: flex
  color: #2563EB
  font-size: 14px
  font-weight: 700
  height: 40px
  gap: 10px
  align-items: center
  padding-bottom: 5px
  user-select: none

.el-popper
  margin-top: 0 !important

.template-summary
  user-select: none
  float: right

.close
  cursor: pointer
  font-size: 16px
</style>
