<template>
  <div class="action-menu">
    <div
      class="section-container"
      v-for="(section, title) in actions"
      :key="title"
    >
      <div class="section-title">
        {{ title }}
      </div>
      <div class="section-items">
        <div v-for="action in section" :key="action.title">
          <div
            class="section-item"
            draggable="true"
            @dragstart="onDragStart($event, action.type)"
            @dragend="onDragEnd"
            :style="{ opacity: isDragging ? '0.5' : '1' }"
          >
            <side-nav-icon :action="action" />
            <div class="section-item-title">{{ action.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ActionIcons } from '@/enums/ActionIconEnum'
import SideNavIcon from '@/views/WorkflowEdit/components/SideNavIcon.vue'

export default {
  name: 'WorkflowSideNav',

  components: { SideNavIcon },

  data() {
    return {
      ActionIcons,
      isDragging: false,
      sectionTitleFontSize: '18px'
    }
  },

  computed: {
    actions() {
      const actions = {
        [this.$t('sidenavCategoryEngagement')]: [],
        [this.$t('sidenavCategoryDelay')]: [
          {
            title: this.$t('sidenavDelayBy'),
            type: 'Delay'
          }
        ],
        [this.$t('sidenavCategoryBranches')]: [
          {
            title: this.$t('sidenavDecision'),
            type: 'Decision'
          },
          {
            title: this.$t('sidenavMultidecision'),
            type: 'MultiDecision'
          },
          {
            title: this.$t('sidenavSplit'),
            type: 'Split'
          }
        ],
        [this.$t('sidenavCategoryUserActions')]: [
          {
            title: 'Event',
            type: 'Event'
          }
        ],
        [this.$t('sidenavCategoryControls')]: [
          {
            title: this.$t('sidenavFinish'),
            type: 'End'
          }
        ]
      }

      if (this.emailEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavEmail'),
          type: 'Email'
        })
      }

      if (this.smsEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavSms'),
          type: 'Sms'
        })
      }

      if (this.iosPushEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavIos'),
          type: 'IosPush'
        })
      }

      if (this.androidPushEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavAndroid'),
          type: 'AndroidPush'
        })
      }

      if (this.webPushEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavWebpush'),
          type: 'WebPush'
        })
      }

      if (this.inboxEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavInbox'),
          type: 'Inbox'
        })
      }

      if (this.webhookEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavWebhook'),
          type: 'Webhook'
        })
      }

      if (this.promotionsEnabled) {
        actions[this.$t('sidenavCategoryUserActions')].push({
          title: this.$t('sidenavPromotions'),
          type: 'Promotion'
        })
      }

      if (this.adRetargetEnabled) {
        actions[this.$t('sidenavCategoryUserActions')].push({
          title: this.$t('sidenavAdRetarget'),
          type: 'AdRetarget'
        })
      }

      if (!this.promotionsEnabled && !this.adRetargetEnabled) {
        delete actions['user actions']
      }

      return actions
    },

    iosPushEnabled() {
      return !!this.$store.state.app.project.projectChannels.ios_push
    },

    androidPushEnabled() {
      return !!this.$store.state.app.project.projectChannels.android_push
    },
    webPushEnabled() {
      return !!this.$store.state.app.project.projectChannels.web_push
    },

    emailEnabled() {
      return this.$store.getters.emailEnabled
    },

    smsEnabled() {
      return this.$store.getters.smsEnabled
    },

    inboxEnabled() {
      return this.$store.getters.inboxEnabled
    },

    webhookEnabled() {
      return this.$store.getters.webhookEnabled
    },

    adRetargetEnabled() {
      return (
        this.$store.getters.isFeatureEnabled('meta') ||
        this.$store.getters.isFeatureEnabled('googleads')
      )
    },

    promotionsEnabled() {
      return this.$store.state.project.workflowPromotions.length > 0
    }
  },

  methods: {
    glyph(action) {
      return require('../../svg/' + action.icon + '.svg')
    },

    onDragStart(event, type) {
      event.dataTransfer.setData('text/plain', type)
      this.isDragging = true
    },

    onDragEnd() {
      this.isDragging = false
    }
  }
}
</script>
<style scoped>
.action-menu {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 64px;
  width: 240px;
  background-color: #ffffff;
  padding-top: 10px;
  max-height: 90%;
  overflow-y: scroll;
}

.section-container {
  width: 220px;
  margin: 0 auto 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #dbdee7;
  }
}
.section-title {
  color: #4b515f;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.section-items {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.section-item {
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  margin-bottom: 5px;
}

.section-item-title {
  font-size: 12px;
  font-weight: 600;
  color: #4b515f;
  text-align: center;
  margin-top: 5px;
}

@media (min-height: 800px) {
  .section-title {
    font-size: 18px;
  }
}

@media (max-height: 800px) {
  .section-item {
    transform: scale(0.85);
  }

  .section-title {
    font-size: 16px;
  }
}

@media (max-height: 600px) {
  .section-item {
    transform: scale(0.75);
  }
  .section-title {
    font-size: 14px;
  }
}
</style>
