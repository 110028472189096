<template>
  <div class="main-ct">
    <div class="label-switch-group">
      <span class="new-ui-label">
        {{ $t('toolBoxSmallTriggerEventAsJsonBtn') }}:
      </span>
      <InputSwitch v-model="config.use_json" @change="toggleEventValue" />
    </div>
    <div class="label-input-group">
      <span class="new-ui-label-no-width">
        {{ $t('toolBoxSmallTriggerEventLabel') }}
        <el-tooltip
          :content="$t('toolBoxSmallTriggerEventToolTip')"
          placement="bottom"
        >
          <i class="pi pi-info-circle"></i>
        </el-tooltip>
      </span>
      <Dropdown
        v-model="eventValue"
        :options="getEventOptions"
        option-label="title"
        editable
        filter
        @filter="onFilter($event.value)"
      />
    </div>

    <div class="label-input-group" v-if="!config.use_json">
      <span class="new-ui-label-no-width">
        {{ $t('toolBoxSmallTriggerEventValueLabel') }}
      </span>
      <InputText v-model="config.event_value" @input="updateAction" />
    </div>

    <div class="json-ct" v-else>
      <div class="toggle-bt">
        <Button
          :label="$t('toolBoxSmallTriggerEventAddJsonRowBtn')"
          v-if="config.use_json"
          @click="addJsonPair()"
        />
      </div>
      <div class="json-key-value-ct">
        <div class="json-fields new-ui-label-no-width">
          {{ $t('toolBoxSmallTriggerEventPropertyColumnHeader') }}
        </div>
        <div class="json-fields new-ui-label-no-width">
          {{ $t('toolBoxSmallTriggerEventValueColumnHeader') }}
        </div>
      </div>
      <div
        class="json-key-value-ct"
        v-for="(value, index) in config.json"
        :key="index"
      >
        <div class="json-fields">
          <InputText
            class="json-fields"
            v-model="value.key"
            @input="updateAction"
          />
        </div>
        <div class="json-fields" style="gap: 5px">
          <InputText
            class="json-fields"
            v-model="value.value"
            @input="updateAction"
          />
          <i class="pi pi-trash" @click="removeRow(index)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import { debounce } from 'lodash'
import XpApi from '@/libs/XpApi'

export default {
  name: 'Event',

  components: { InputText, Button, Dropdown, InputSwitch },

  props: {
    action: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: true,
      default: false
    },
    workflow: {
      type: Object,
      required: true
    }
  },

  inject: ['workflowApi'],

  mounted() {
    Object.assign(this.config, this.action.config)
  },

  data() {
    return {
      config: {
        event_title: '',
        event_type: '',
        event_value: '',
        use_json: false,
        json: []
      },
      eventSearch: []
    }
  },

  methods: {
    addJsonPair() {
      if (!this.config.use_json) {
        this.config.use_json = true
      }
      if (this.config.event_value) {
        this.config.event_value = ''
      }
      this.config.json.push({ key: '', value: '' })
      this.updateAction()
    },

    removeRow(index) {
      this.config.json.splice(index, 1)
      if (!this.config.json.length) {
        this.config.use_json = false
      }
      this.updateAction()
    },

    updateAction: debounce(function () {
      this.workflowApi.updateAction(this.action.id, { config: this.config })
    }, 500),

    toggleEventValue() {
      if (this.config.use_json) {
        this.addJsonPair()
      } else {
        this.config.json = []
        this.updateAction()
      }
    },

    onFilter(value) {
      if (!value) {
        this.eventSearch = []
        return
      }

      XpApi.get(`projects/${this.$route.params.projectId}/events`, {
        params: {
          title: value
        }
      }).then(({ data }) => {
        this.eventSearch = data
      })
    }
  },

  computed: {
    getEventOptions() {
      if (this.eventSearch.length) {
        return this.eventSearch
      }
      return this.$store.state.project.xpEvents
    },

    eventValue: {
      get() {
        return this.config.event_title
      },
      set(newValue) {
        if (typeof newValue === 'object') {
          this.config.event_title = newValue.title
          this.config.event_type = newValue.type
          this.updateAction()
          return
        }
        this.config.event_title = newValue
        this.config.event_type = 'custom'
        this.updateAction()
      }
    }
  }
}
</script>

<style scoped>
.main-ct {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
}

.new-ui-label-no-width {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #4b515f;
  font-weight: 600;
}

.label-input-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.json-key-value-ct {
  display: flex;
  gap: 10px;
  align-items: center;
}

.pi-trash {
  cursor: pointer;
}

.json-fields {
  display: flex;
  flex: 1;
  align-items: center;
}

.json-ct {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.toggle-bt {
  display: flex;
  justify-content: flex-end;
}

.label-switch-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.new-ui-label {
  color: #4b515f;
  font-weight: 600;
}
</style>
