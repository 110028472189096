<template>
  <input
    v-model="text"
    class="form-control"
    type="text"
    :placeholder="$t('insertTextPlaceholder')"
    @input="$emit('input', { value: text })"
    :disabled="readOnly"
  />
</template>

<script>
export default {
  name: 'WebhookTextResponseBodyContains',

  props: {
    value: {
      type: [Object, Array],
      required: true
    },

    readOnly: {
      type: Boolean,
      required: true
    }
  },

  emits: ['input'],

  data() {
    return {
      text: this.value.value || ''
    }
  }
}
</script>

<style scoped></style>
