<template>
  <div class="stats-ct">
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-arrived" />
      <span>{{ $t('actionTooltipArrived') }}</span>
      <span class="stat-number">
        {{ numberFilter(stats['Event:completed']) }}
      </span>
    </span>
  </div>
</template>

<script>
import { numberFilter } from '@/helpers/filters'
export default {
  name: 'EventStat',

  props: {
    stats: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      numberFilter
    }
  }
}
</script>

<style scoped>

</style>
