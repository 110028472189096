import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=11d56c63"
import script from "./Editor.vue?vue&type=script&lang=js"
export * from "./Editor.vue?vue&type=script&lang=js"
import style0 from "./Editor.vue?vue&type=style&index=0&id=11d56c63&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports