<template>
  <input
    v-model="code"
    class="form-control"
    type="text"
    placeholder="Insert Webhook Code"
    @input="$emit('input', { value: code })"
    :disabled="readOnly"
  />
</template>

<script>
export default {
  name: 'WebhookCode',

  props: {
    value: {
      type: [Object, Array],
      required: true
    },

    readOnly: {
      type: Boolean,
      required: true
    }
  },

  emits: ['input'],

  data() {
    return {
      code: this.value.value || ''
    }
  }
}
</script>

<style scoped></style>
