const MessageTypes = Object.freeze({
  PUSH_IOS: 1,
  PUSH_ANDROID: 2,
  PUSH_WEB: 3,
  INBOX: 6,
  EMAIL: 7,
  WEBHOOK: 8,
  SMS: 9
})

const PushTypes = Object.freeze({
  1: 'ios',
  2: 'android',
  3: 'web'
})

export { MessageTypes, PushTypes }
