<template>
  <Dropdown
    :placeholder="$t('selectAProfileAttributeLabel')"
    :disabled="!options.length || loading || disabled"
    :value="objectValue"
    @change="onChange"
    :options="options"
    :option-label="customLabel"
    option-value="name"
    data-key="id"
    filter
    :loading="loading"
  />
</template>
<script>
import Dropdown from 'primevue/dropdown'

export default {
  components: {
    Dropdown
  },
  props: {
    options: {
      required: true,
      type: Array
    },
    value: {
      required: true
    },
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },

  emits: ['input'],

  data() {
    return {
      selectedProfileAttribute: null
    }
  },

  computed: {
    objectValue() {
      return this.options.find((option) => option.name === this.value)?.name
    }
  },

  methods: {
    customLabel(option) {
      return option.title || option.name
    },

    onChange(profileAttribute) {
      this.$emit('input', profileAttribute.value)
    }
  }
}
</script>
