<template>
  <div class="delay-ct">
    <div class="label-input-group">
      <span class="label-new-ui">
        {{ $t('delayToolboxSmallDelayDelayBy') }}
      </span>
      <Dropdown
        v-model="selectedDelayOption"
        :options="options"
        option-label="name"
        :disabled="readOnly"
        @change="saveType"
        :placeholder="$t('adRetargetToolboxSmallOptionSelectPlaceholder')"
      />
    </div>
    <component
      :is="action.options.type"
      :action="action"
      :read-only="readOnly"
    />
  </div>
</template>
<script>
import ProfileAttribute from './Delay/ProfileAttribute'
import RelativeDate from './Delay/RelativeDate'
import SpecificDate from './Delay/SpecificDate'
import SpecificTime from './Delay/SpecificTime'
import TimePeriod from './Delay/TimePeriod'
import Dropdown from 'primevue/dropdown'

export default {
  components: {
    ProfileAttribute,
    RelativeDate,
    SpecificDate,
    SpecificTime,
    TimePeriod,
    Dropdown
  },

  inject: ['workflowApi'],

  props: ['action', 'readOnly'],

  data() {
    return {
      selectedDelayOption: null
    }
  },

  computed: {
    options() {
      return [
        {name: this.$t('delayToolboxSmallProfileAttribute'), value: 'profileAttribute'},
        {name: this.$t('delayToolboxSmallRelativeDate'), value: 'relativeDate'},
        {name: this.$t('delayToolboxSmallSpecificDate'), value: 'specificDate'},
        {name: this.$t('delayToolboxSmallSpecificTime'), value: 'specificTime'},
        {name: this.$t('delayToolboxSmallTimePeriod'), value: 'timePeriod'}
      ]
    },

    optionsType() {
      let type = this.action.options.type

      switch (type) {
        case 'profileAttribute':
          return {
            name: this.$t('delayToolboxSmallProfileAttribute'),
            value: type
          }

        case 'relativeDate':
          return { name: this.$t('delayToolboxSmallRelativeDate'), value: type }

        case 'specificDate':
          return { name: this.$t('delayToolboxSmallSpecificDate'), value: type }

        case 'specificTime':
          return {name: this.$t('delayToolboxSmallSpecificTime'), value: type}

        case 'timePeriod':
          return { name: this.$t('delayToolboxSmallTimePeriod'), value: type }
      }
    }
  },

  created() {
    this.$store.dispatch('fetchAttributes')
  },

  mounted() {
    this.selectedDelayOption = this.optionsType
  },

  methods: {
    optionLabels(option) {
      return `${option.name}`
    },

    saveType(type) {
      let options = {
        type: type.value.value
      }

      switch (options.type) {
        case 'profileAttribute':
          Object.assign(options, {
            profileAttribute: ''
          })
          break

        case 'relativeDate':
          Object.assign(options, {
            interval: 'P1D',
            beforeOrAfter: 'after',
            relativeTo: 'profileAttribute',
            profileAttribute: '',
            eventAttribute: ''
          })
          break

        case 'specificDate':
          Object.assign(options, {
            useTime: false,
            delayUntil: ''
          })
          break

        case 'specificTime':
          Object.assign(options, {
            time: '',
            followingDay: true
          })
          break

        case 'timePeriod':
          Object.assign(options, {
            interval: 'P1D'
          })
          break
      }

      this.workflowApi.updateAction(this.action.id, { options })
    }
  }
}
</script>
<style scoped>
.delay-ct {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label-input-group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.label-new-ui {
  color: #4b515f;
  font-weight: 600;
}
</style>
