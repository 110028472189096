<template>
  <div class="webhook">
    <div class="endpoint">
      <span class="type">POST</span>
      <span class="url">{{ message.url }}</span>
    </div>
    <div class="headers" v-html="message.headers || 'header'" />
    <div class="body" v-html="message.body || 'body'" />
  </div>
</template>

<script>
export default {
  name: 'WebhookPreview',
  props: {
    message: Object
  }
}
</script>

<style scoped>
.webhook {
  border: 1px solid #c2c4c6;
  background: #f2f4f6;
  padding: 10px;
  word-wrap: break-word;
  overflow: hidden;
  width: 100%;
}
.body {
  margin-top: 2px;
}
</style>
