<script>
import Action from './AbstractAction'

export default {
  name: 'Event',
  extends: Action,
  data() {
    return {
      // @todo need an icon
      icon: 'channel-webhook'
    }
  }
}
</script>

<style scoped></style>
