<template>
  <div>
    <input
      v-model="jsonKey"
      class="form-control"
      type="text"
      placeholder="Insert Json key"
      :disabled="readOnly"
      @input="onInput"
    />
    <span class="text-and">:</span>
    <input
      v-model="jsonValue"
      class="form-control"
      type="text"
      placeholder="Insert Json value"
      :disabled="readOnly"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  name: 'WebhookJsonResponseBody',

  props: {
    value: {
      type: [Object, Array],
      required: true
    },

    readOnly: {
      type: Boolean,
      required: true
    }
  },

  emits: ['input'],

  data() {
    return {
      jsonKey: this.value.responseJsonKey || '',
      jsonValue: this.value.responseJsonValue || ''
    }
  },

  methods: {
    onInput() {
      const args = {
        responseJsonKey: this.jsonKey,
        responseJsonValue: this.jsonValue
      }

      this.$emit('input', args)
    }
  }
}
</script>

<style scoped></style>
