<template>
  <div
    class="action"
    :class="{
      'action-selected': selected,
      'multi-select-cursor-allowed': shiftHover && !readOnly,
      'multi-select-cursor-not-allowed': shiftHover && readOnly,
      'grab-mode': grabMode
    }"
    :style="actionStyle"
    :data-action-id="action.id"
    @click="onClick"
    @mousedown="onMouseDown"
    @mouseover="onMouseOver"
    @mousemove="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <div style="display: flex; flex-direction: column; gap: 5px">
      <action-new-ui
        :grab-mode="grabMode"
        :style="selectedActionStyles"
        :action="action"
        :errored="errored"
        @toggle-analytics="toggleAnalytics"
      >
        <template #stats>
          <component
            :is="actionStat(action)"
            class="action-stats"
            :stats="action.stats"
          />
          <div class="view-more">
            <span @click="showReporting">{{ $t('actionViewMore') }}</span>
          </div>
        </template>
      </action-new-ui>
      <div class="action-options-ct">
        <div class="action-options">
          <div class="action-option-yes">{{ $t('actionDecisionControlsYes') }}</div>
          <div class="action-option-no">{{ $t('actionDecisionControlsNo') }}</div>
        </div>
      </div>
    </div>
    <action-controls
      v-if="!dragging"
      @mouseover="inControls = true"
      @mouseleave="inControls = false"
      :show-controls="showControls"
      :read-only="readOnly"
      :workflow="workflow"
      :action="action"
      :grab-mode="grabMode"
      :selected-actions="selectedActions"
    />
  </div>
</template>
<script>
import { CELL_SIZE } from '../GridSize'
import Action from './AbstractAction'
import ActionControls from '@/views/WorkflowEdit/Actions/components/ActionControls.vue'

export default {
  components: { ActionControls },

  extends: Action,

  data() {
    return {
      icon: 'question-mark'
    }
  },

  successorAnchors: {
    yes: [0.25, 1, 0, 1],
    no: [0.75, 1, 0, 1]
  },

  successorOffset: {
    yes: {
      left: -(CELL_SIZE * 8),
      top: CELL_SIZE * 4
    },
    no: {
      left: CELL_SIZE * 8,
      top: CELL_SIZE * 4
    }
  }
}
</script>
<style scoped lang="sass">
.action
    display: flex

.action-options
  display: flex
  gap: 5px

.action-option-yes,
.action-option-no
    width: 50%
    float: left
    padding: 12px 0
    text-transform: uppercase
    color: #fff
    text-align: center
    border: 1px solid var(--teal-400, #41c5b7)
    border-radius: 4px

.action-option-yes
    background-color: #059669

.action-option-no
    background-color: #e11d48

.action-overlay
    border-radius: 0 5px 0 0
</style>
