const ActionIcons = Object.freeze({
  Start: {
    icon: 'action-start',
    color: '#10B981',
    fill: '#ECFDF5'
  },
  Email: {
    icon: 'action-email',
    color: '#2563EB',
    fill: '#E6F3FB'
  },
  Sms: {
    icon: 'action-sms',
    color: '#2563EB',
    fill: '#E6F3FB'
  },
  Push: {
    icon: 'action-push',
    color: '#2563EB',
    fill: '#E6F3FB'
  },
  IosPush: {
    icon: 'action-sms',
    color: '#2563EB',
    fill: '#E6F3FB'
  },
  AndroidPush: {
    icon: 'action-email',
    color: '#2563EB',
    fill: '#E6F3FB'
  },
  WebPush: {
    icon: 'action-inbox',
    color: '#2563EB',
    fill: '#E6F3FB'
  },
  Inbox: {
    icon: 'action-inbox',
    color: '#2563EB',
    fill: '#E6F3FB'
  },
  Webhook: {
    icon: 'action-webhook',
    color: '#2563EB',
    fill: '#E6F3FB'
  },
  Delay: {
    icon: 'action-delay',
    color: '#F97316',
    fill: '#FFF8F3'
  },
  End: {
    icon: 'action-end',
    color: '#1E3A8A',
    fill: '#1E3A8A1A'
  },
  Promotion: {
    icon: 'action-promotion',
    color: '#B975F9',
    fill: '#FBF7FF'
  },
  PromotionAdd: {
    icon: 'action-promotion',
    color: '#B975F9',
    fill: '#FBF7FF'
  },
  PromotionRemove: {
    icon: 'action-promotion',
    color: '#B975F9',
    fill: '#FBF7FF'
  },
  AdRetarget: {
    icon: 'action-adretargeting',
    color: '#B975F9',
    fill: '#FBF7FF'
  },
  AdRetargetRemove: {
    icon: 'action-adretargeting',
    color: '#B975F9',
    fill: '#FBF7FF'
  },
  MultiDecision: {
    icon: 'action-multidecision',
    color: '#14B8A6',
    fill: '#F3FBFB'
  },
  Split: {
    icon: 'action-split',
    color: '#14B8A6',
    fill: '#F3FBFB'
  },
  Decision: {
    icon: 'action-decision',
    color: '#14B8A6',
    fill: '#F3FBFB'
  },
  Event: {
    icon: 'action-webhook',
    color: '#2563EB',
    fill: '#E6F3FB'
  },
})

export { ActionIcons }
