<template>
  <div class="sidebar sidebar-lg">
    <div
      class="sidebar-header"
      :class="`action-style-${action.type.toLowerCase()}`"
    >
      <action-icon
        class="sidebar-icon"
        :actionType="workflowApi.getActionType(action)"
        size="38"
      />
      <div class="sidebar-title">
        <template v-if="readOnly">{{ action.name }}</template>
        <editable v-else :value="action.name" @input="changeName" />
      </div>
      <button
        type="button"
        class="close"
        :aria-label="$t('closeButton')"
        @click="$emit('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="sidebar-body">
      <component
        :key="action.id"
        :is="actionType(action)"
        :workflow="workflow"
        :action="action"
        :readOnly="readOnly"
        @close="$emit('close')"
      ></component>
      <highlight
        v-if="devMode"
        :value="action"
        lang="json"
        style="margin: 20px; background-color: #333"
      />
    </div>

  </div>
</template>
<script>
import Highlight from '@/components/Highlight.vue'

export default {
  components: { Highlight },
  props: ['workflow', 'action', 'readOnly'],

  inject: ['workflowApi'],

  methods: {
    actionType(action) {
      return require(`./ToolboxesLarge/${action.type}`).default
    },

    changeName(name, cb) {
      this.workflowApi.updateAction(this.action.id, { name }).then(() => cb())
    }
  },

  computed: {
    devMode() {
      return window.devMode
    }
  }
}
</script>
<style lang="css" scoped>
/**
 * Remove these when vue-multiselect is upgraded to 2.1.17
 * https://github.com/shentao/vue-multiselect/pull/1350
 * this is the commit that adds what I think we need to vue-multiselect
 */

.sidebar {
  overflow: scroll;
}

.sidebar-body {
  padding-left: 20px;
  padding-right: 20px;
}

::v-deep .sidebar-body > .p-tabview > .p-tabview-nav-container,
::v-deep .sidebar-body > div > .p-tabview > .p-tabview-nav-container {
  position: sticky;
  top: 62px;
  z-index: 6;
}

::v-deep .p-tabview-panels {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .v-switch-button {
  z-index: 1 !important;
}

::v-deep .input-group input.form-control[type='text'] {
  z-index: 1 !important;
}
</style>
