<template>
  <div class="wrapper" :class="{ expanded: expanded }">
    <div class="vertical-layout">
      <div class="header">
        <div class="app-icon"><img :src="appIcon" /></div>
        <div class="app-name">
          {{ appName ? appName : $t('devicePreviewAppNamePlaceholder') }}
        </div>
        <div class="separator">•</div>
        <div class="date">now</div>
        <div v-if="showToggler" class="toggler" @click="toggle">
          <div class="up" v-if="expanded"><i class="pi pi-angle-up"></i></div>
          <div class="down" v-else><i class="pi pi-angle-down"></i></div>
        </div>
      </div>

      <div class="horizontal-layout">
        <div class="content">
          <div class="title">
            {{
              message.title
                ? message.title
                : $t('devicePreviewTitlePlaceholder')
            }}
          </div>
          <div class="text" style="white-space: break-spaces">
            <span>{{ messageText }}</span>
          </div>
        </div>
        <div v-if="showIcon" class="icon">
          <img :src="message.picture" />
        </div>
      </div>
    </div>

    <div v-if="showPicture" class="picture">
      <img :src="message.picture" />
    </div>

    <div v-if="showActions" class="actions">
      <div v-for="action in visibleActions" class="action">{{ action }}</div>
    </div>
  </div>
</template>

<script>
import defaultIcon from '@/images/app-ico.png'

export default {
  name: 'PushPreviewAndroid',
  props: {
    message: Object,
    appIcon: {
      type: String,
      default: defaultIcon
    },
    appName: String
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    showActions: function () {
      return this.expanded && this.visibleActions.length
    },

    showPicture: function () {
      return this.expanded && this.message.picture
    },

    showIcon: function () {
      return !this.expanded && this.message.picture
    },
    visibleActions: function () {
      if (this.message.actions && this.message.actions.length) {
        return this.message.actions
      } else {
        return []
      }
    },

    showToggler: function () {
      return this.message.picture || this.visibleActions.length
    },

    messageText: function () {
      return this.message.text
        ? this.message.text
        : this.$t('devicePreviewTextPlaceholder')
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style scoped>
.app-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wrapper {
  border: 1px solid rgb(190, 190, 190);
  background: #f5f5f5;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  cursor: default;
  position: relative;
  text-align: left;
}
.vertical-layout {
  padding: 0 10px;
}

.header {
  display: flex;
  color: #757575;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  margin-top: 4px;
  margin-bottom: 2px;
  white-space: nowrap;
}
.app-icon {
  margin-right: 5px;
}
.app-icon img {
  width: 18px;
  height: 18px;
}
.separator {
  margin: 0 5px;
  font-size: 10px;
  line-height: 18px;
}
.date {
  overflow: hidden;
  text-overflow: ellipsis;
}

.horizontal-layout {
  display: flex;
  margin-bottom: 15px;
}
.content {
  flex: 1;
  font-size: 13px;
  line-height: 15px;
  min-width: 0;
}
.title {
  color: #646464;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 15px;
  max-height: 18px;
  margin-bottom: 3px;
  white-space: nowrap;
}
.text {
  color: #757575;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: break-spaces;
}
.expanded .text {
  height: auto;
  white-space: normal;
  max-height: 91px;
}

.icon {
  float: right;
  width: 40px;
  margin-left: 10px;
}
.icon img {
  width: 40px;
  height: 40px;
}

.picture {
  margin: 10px;
  text-align: center;
}
.picture img {
  max-height: 150px;
  max-width: 100%;
}

.actions {
  background-color: #eeeeee;
  margin-top: 10px;
  padding: 0 4px;
  display: flex;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.actions .action {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  color: #757575;
  font-size: 12px;
}

.header {
  line-height: 16px;
  margin: 10px 0px;
}
.toggler {
  margin-left: auto;
  cursor: pointer;
}
</style>
