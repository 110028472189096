<template>
  <div style="padding: 20px">
    <label>{{ $t('actionReportingWorkflowStats') }}</label>
    <div class="stats-grid">
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="source" />
        <div class="stats-grid-item-title">
          {{ $t('actionReportingStarted') }}
        </div>
        <div class="stats-grid-item-number">
          {{ numberFilter(startedTotal) }}
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="flag" />
        <div class="stats-grid-item-title">
          {{ $t('actionReportingCompleted') }}
        </div>
        <div class="stats-grid-item-number">
          {{ numberFilter(completedTotal) }}
          ({{ percentageOfFilter(completedTotal, startedTotal) }})
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="clock" />
        <div class="stats-grid-item-title">
          {{ $t('actionReportingCurrentlyWaiting') }}
        </div>
        <div class="stats-grid-item-number">
          {{ numberFilter(waitingTotal) }}
        </div>
      </div>
    </div>

    <template v-if="channels.email">
      <label>{{ $t('actionReportingEmailDeliveryStats') }}</label>
      <div class="stats-grid">
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="email" />
          <div class="stats-grid-item-title">
            {{ $t('actionReportingSent') }}
          </div>
          <div class="stats-grid-item-number">
            {{ numberFilter(emailSentTotal) }} /
            {{ percentageOfFilter(emailSentTotal, emailArrivedTotal) }}
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="inbox" />
          <div class="stats-grid-item-title">
            {{ $t('actionReportingDelivered') }}
          </div>
          <div class="stats-grid-item-number">
            {{ numberFilter(emailDeliveredTotal) }} /
            {{ percentageOfFilter(emailDeliveredTotal, emailSentTotal) }}
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="arrow-redirect" />
          <div class="stats-grid-item-title">
            {{ $t('actionReportingBounced') }}
          </div>
          <div class="stats-grid-item-number">
            {{ numberFilter(emailBouncesTotal) }} /
            {{ percentageOfFilter(emailBouncesTotal, emailSentTotal) }}
          </div>
        </div>
      </div>
      <label>{{ $t('actionReportingEmailEngagementStats') }}</label>
      <div class="stats-grid">
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="email-open" />
          <div class="stats-grid-item-title">
            {{ $t('actionReportingOpens') }}
          </div>
          <div class="stats-grid-item-number">
            <span v-if="isEmailOpenTrackingEnabled">
              {{ numberFilter(emailUniqueOpensTotal) }} /
              {{
                percentageOfFilter(emailUniqueOpensTotal, emailDeliveredTotal)
              }}
            </span>
            <span v-else>{{ $t('actionReportingNA') }}</span>
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="mouse" />
          <div class="stats-grid-item-title">{{ $t('actionReportingClicks') }}</div>
          <div class="stats-grid-item-number">
            <span v-if="isEmailClickTrackingEnabled">
              {{ numberFilter(emailUniqueClicksTotal) }} /
              {{
                percentageOfFilter(emailUniqueClicksTotal, emailDeliveredTotal)
              }}
            </span>
            <span v-else>{{ $t('actionReportingNA') }}</span>
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="email-unsubscribe" />
          <div class="stats-grid-item-title">
            {{ $t('actionReportingUnsubscribes') }}
          </div>
          <div class="stats-grid-item-number">
            {{ numberFilter(emailUnsubscribesTotal) }} /
            {{
              percentageOfFilter(emailUnsubscribesTotal, emailDeliveredTotal)
            }}
          </div>
        </div>
      </div>
    </template>

    <template v-if="channels.push">
      <label>{{ $t('actionReportingPushStats') }}</label>
      <div class="stats-grid">
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="push" />
          <div class="stats-grid-item-title">{{ $t('actionReportingSent') }}</div>
          <div class="stats-grid-item-number">
            {{ numberFilter(pushSentTotal) }} /
            {{ percentageOfFilter(pushSentTotal, pushArrivedTotal) }}
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="mobile" />
          <div class="stats-grid-item-title">{{ $t('actionReportingDelivered') }}</div>
          <div class="stats-grid-item-number">
            {{ numberFilter(pushDeliveredTotal) }} /
            {{ percentageOfFilter(pushDeliveredTotal, pushSentTotal) }}
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="mouse" />
          <div class="stats-grid-item-title">{{ $t('actionReportingClicked') }}</div>
          <div class="stats-grid-item-number">
            {{ numberFilter(pushClickedTotal) }} /
            {{ percentageOfFilter(pushClickedTotal, pushDeliveredTotal) }}
          </div>
        </div>
      </div>
    </template>

    <template v-if="channels.sms">
      <label>{{ $t('actionReportingSmsDeliveryStats') }}</label>
      <div class="stats-grid">
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="sms" />
          <div class="stats-grid-item-title">{{ $t('actionReportingSent') }}</div>
          <div class="stats-grid-item-number">
            {{ numberFilter(smsSentTotal) }}
            ({{ percentageOfFilter(smsSentTotal, smsArrivedTotal) }})
          </div>
        </div>
      </div>
    </template>

    <template v-if="channels.webhook">
      <label>{{ $t('actionReportingWebhookDeliveryStats') }}</label>
      <div class="stats-grid">
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="webhook" />
          <div class="stats-grid-item-title">{{ $t('actionReportingSent') }}</div>
          <div class="stats-grid-item-number">
            {{ numberFilter(webhookSentTotal) }}
            ({{ percentageOfFilter(webhookSentTotal, webhookArrivedTotal) }})
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="webhook" />
          <div class="stats-grid-item-title">{{ $t('actionReportingDelivered') }}</div>
          <div class="stats-grid-item-number">
            {{ numberFilter(webhookDeliveredTotal) }} /
            {{ percentageOfFilter(webhookDeliveredTotal, webhookSentTotal) }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { numberFilter, percentageOfFilter } from '@/helpers/filters'

export default {
  props: [
    'startedTotal',
    'completedTotal',
    'waitingTotal',

    'emailArrivedTotal',
    'emailSentTotal',
    'emailDeliveredTotal',
    'emailBouncesTotal',
    'emailUniqueOpensTotal',
    'emailUniqueClicksTotal',
    'emailUnsubscribesTotal',

    'smsArrivedTotal',
    'smsSentTotal',

    'pushSentTotal',
    'pushArrivedTotal',
    'pushDeliveredTotal',
    'pushClickedTotal',

    'webhookSentTotal',
    'webhookDeliveredTotal',
    'webhookFailedTotal',
    'webhookArrivedTotal',

    'channels',

    'workflow'
  ],

  data() {
    return {
      numberFilter,
      percentageOfFilter
    }
  },

  computed: {
    isEmailOpenTrackingEnabled() {
      return this.workflow.features.email_open_tracking !== undefined
        ? this.workflow.features.email_open_tracking
        : true
    },

    isEmailClickTrackingEnabled() {
      return this.workflow.features.email_click_tracking !== undefined
        ? this.workflow.features.email_click_tracking
        : true
    }
  }
}
</script>
