<template>
  <div v-loading="aiLoading" :class="type === 'textarea' ? 'textarea-input-group' : 'input-group'">
    <InputText
      v-if="type === 'text'"
      :id="id !== '' ? id : false"
      ref="input"
      type="text"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      @blur="onChange"
      class="input"
    />
    <Textarea
      v-if="type === 'textarea'"
      :id="id !== '' ? id : false"
      ref="input"
      :value="value"
      :placeholder="placeholder"
      class="form-control"
      :rows="rows"
      :disabled="disabled"
      @blur="onChange"
    />
    <div class="input-group-btn helper-btns">
      <div v-if="emojiEnabled" class="message-emoji-picker">
        <keep-alive>
          <picker
            v-if="showEmoji"
            v-click-outside="hideEmoji"
            color="#1d87c8"
            :auto-focus="true"
            :show-preview="false"
            :infinite-scroll="false"
            :per-line="8"
            :sheet-size="32"
            :disabled="disabled"
            @select="selectEmoji"
          />
        </keep-alive>
      </div>
      <div
        v-if="emojiEnabled"
        class="btn btn-default"
        :style="type === 'textarea' ? 'border-top-right-radius: 4px' : ''"
        :disabled="disabled"
        @click.stop="disabled ? '' : (showEmoji = true)"
      >
        &#x263A;
      </div>

      <div
        v-if="showAttributes"
        v-click-outside="hideAttributes"
        class="open"
        style="position: absolute; right: 0; top: 32px"
      >
        <ul class="dropdown-menu dropdown-menu-right">
          <li style="padding: 10px">
            <profile-attributes
              :disabled="disabled"
              v-model="profileAttribute"
              style="width: 300px"
              :options="profileAttributes"
              @input="selectAttribute"
            />
          </li>
        </ul>
      </div>

      <div
        v-if="aiEnabled && aiTextGeneration"
        :disabled="disabled"
        class="btn btn-default"
        @click="getAiSubjectLine"
      >
        <icon glyph="ai" />
      </div>

      <div
        v-if="attributesEnabled"
        class="btn btn-default"
        :disabled="disabled"
        @click.stop="disabled ? '' : (showAttributes = true)"
      >
        <span class="glyphicon glyphicon-user" />
      </div>

      <div
        v-if="eventPropertiesEnabled && showEventProperties"
        v-click-outside="hideEventProperties"
        class="open"
        style="position: absolute; right: 0; top: 64px"
      >
        >
        <ul class="dropdown-menu dropdown-menu-right">
          <li style="padding: 10px">
            <input
              :disabled="disabled"
              ref="eventPropertyName"
              v-model="eventPropertyName"
              style="width: 300px"
              type="text"
              :placeholder="$t('enterEventPropertyNameLabel')"
              class="form-control"
              @keyup.enter="insertEventPropertyPlaceholder"
              @keyup.esc="hideEventProperties"
            />
          </li>
        </ul>
      </div>
      <div
        class="btn btn-default"
        :disabled="disabled"
        @click.stop="eventPropertiesClick"
        v-if="eventPropertiesEnabled"
      >
        <span class="glyphicon glyphicon-flag" />
      </div>
    </div>
  </div>
</template>

<script>
import { Picker } from 'emoji-mart-vue'
import vClickOutside from 'v-click-outside'
import ProfileAttributes from '@/components/ProfileAttributes'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import XpApi from '@/libs/XpApi'
import * as Toastr from 'toastr'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },

  components: {
    Picker,
    ProfileAttributes,
    InputText,
    Textarea
  },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['text', 'textarea'].includes(value)
      }
    },
    value: {
      type: String,
      required: true,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    rows: {
      type: String
    },
    profileAttributes: {
      type: Array,
      default: () => []
    },
    emojiEnabled: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    eventPropertiesEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
    aiEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    emailContent: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['input'],

  data() {
    return {
      profileAttribute: '',
      showAttributes: false,
      showEmoji: false,
      showEventProperties: false,
      eventPropertyName: '',
      aiLoading: false
    }
  },

  computed: {
    attributesEnabled() {
      return this.profileAttributes.length > 0
    },

    aiTextGeneration() {
      return this.$store.getters.isFeatureEnabled('aiTextGeneration')
    }
  },

  methods: {
    onChange(event) {
      this.$emit('input', event.target.value)
    },

    selectEmoji(emoji) {
      this.showEmoji = false
      this.insertText(emoji.native)
    },

    selectAttribute(profileAttribute) {
      this.showAttributes = false
      this.profileAttribute = ''
      this.insertText(`{{ ${profileAttribute} }}`)
    },

    getAiSubjectLine() {
      if (this.aiLoading) {
        return
      }

      let emailText = ''
      if (this.emailContent) {
        emailText = new DOMParser()
          .parseFromString(this.emailContent, 'text/html')
          .body.textContent.trim()
          .replace(/\r?\n|\r|\t/g, ' ')
          .replace(/\s+/g, ' ')
      }

      if (!emailText) {
        this.displayErrorDialog(this.$t('emailAiSubjectLineError'))
        return
      }

      this.aiLoading = true
      let projectId = this.$route.params.projectId
      XpApi.post('projects/' + projectId + '/generate/subject-line', {
        type: 'email',
        param: emailText
      })
        .then((response) => {
          this.$emit('input', response.data.result)
          this.aiLoading = false
        })
        .catch((response) => {
          this.displayErrorDialog(response.data.message)
          this.aiLoading = false
        })
    },

    displayErrorDialog(msg) {
      Toastr.error(msg)
    },

    insertText(value) {
      const input = this.$refs['input'].$el
      const startPos = input.selectionStart
      const endPos = input.selectionEnd

      input.value =
        input.value.substring(0, startPos) +
        value +
        input.value.substring(endPos, input.value.length)

      this.$emit('input', this.$refs['input'].$el.value)

      this.$nextTick(() => {
        input.selectionStart = endPos + value.length
        input.selectionEnd = endPos + value.length
      })
    },

    hideEmoji() {
      this.showEmoji = false
    },

    hideAttributes() {
      this.showAttributes = false
    },

    hideEventProperties() {
      this.showEventProperties = false
      this.eventPropertyName = ''
    },

    eventPropertiesClick() {
      if (this.disabled) {
        return
      }
      this.showEventProperties = true
      this.$nextTick(() => {
        this.$refs.eventPropertyName.focus()
      })
    },

    insertEventPropertyPlaceholder() {
      this.showEventProperties = false
      this.insertText(`{{ event.${this.eventPropertyName} }}`)
      this.eventPropertyName = ''
    }
  }
}
</script>

<style lang="sass" scoped>
.helper-btns .btn
  padding: 8.8px 15px

.input
  width: 100%
  border-top-right-radius: 0
  border-bottom-right-radius: 0

.message-emoji-btn
    position: absolute
    top: 3px
    right: 3px

.message-emoji-picker
    position: absolute
    top: 34px
    right: 0
    z-index: 3

.btn.btn-default
    background-color: #ffffff

.textarea-input-group
    display: flex

    textarea.form-control
        flex: 1
        border-top-right-radius: 0
        resize: none

    .input-group-btn
        display: flex
        flex-direction: column
        width: 37px

        .btn
            border-bottom-width: 0
            border-radius: 0
            border-left: 0
            margin-left: 0
            display: flex
            justify-content: center
            align-items: center
            height: calc(100% / 3)

        .btn:last-child
            border-bottom-right-radius: 4px
            border-bottom-width: 1px

.message-emoji-picker ::v-deep .emoji-mart-category
    white-space: normal
</style>
