<template>
  <div class="time-period-ct">
    <InputText
      ref="unitValue"
      type="number"
      v-model="theUnitValue"
      :min="1"
      :max="999"
      :disabled="disabled"
      @change="updateUnitValue"
      :use-grouping="false"
    />
    <Dropdown
      style="flex: 1"
      ref="unitType"
      :disabled="disabled"
      v-model="theUnitType"
      :options="unitTypeOptions"
      option-label="label"
      option-value="value"
      @change="updateUnitType"
    />
  </div>
</template>
<script>
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
const regex = /(PT?)(\d*)([MYDH]?)/
export default {
  components: {
    InputText,
    Dropdown
  },

  props: {
    value: {
      type: String,
      require: true
    },

    disabled: {
      type: Boolean
    }
  },

  emits: ['input'],

  data() {
    return {
      theUnitValue: 1,
      theUnitType: null,
      unitTypeOptions: [
        { value: 'TM', label: this.$t('simpleTimePeriodMinutes') },
        { value: 'TH', label: this.$t('simpleTimePeriodHours') },
        { value: 'D', label: this.$t('simpleTimePeriodDays') },
        { value: 'M', label: this.$t('simpleTimePeriodMonths') },
        { value: 'Y', label: this.$t('simpleTimePeriodYears') }
      ]
    }
  },

  mounted() {
    if (this.unitValue) {
      this.theUnitValue = this.unitValue
    } else {
      this.theUnitValue = 1
    }
    this.theUnitType = this.unitType
  },

  computed: {
    unitValue() {
      const result = regex.exec(this.value)

      if (result) {
        return result[2]
      }

      return 1
    },

    unitType() {
      const result = regex.exec(this.value)

      if (result) {
        if (result[1] === 'PT') {
          return 'T' + result[3]
        }

        return result[3]
      }

      return null
    }
  },

  methods: {
    updateUnitValue(event) {
      const interval = this.createInterval(event.target.value, this.unitType)
      this.$emit('input', interval)
    },

    updateUnitType(unitType) {
      const interval = this.createInterval(this.unitValue, unitType.value)
      this.$emit('input', interval)
    },

    createInterval(unitValue, unitType) {
      let interval = ''

      switch (unitType) {
        case 'TM':
          interval = 'PT' + unitValue + 'M'
          break
        case 'TH':
          interval = 'PT' + unitValue + 'H'
          break
        case 'D':
        case 'M':
        case 'Y':
          interval = 'P' + unitValue + unitType
          break

        default:
          interval = 'P' + unitValue
      }

      return interval
    }
  }
}
</script>
<style scoped>
.time-period-ct {
  display: flex;
  gap: 10px;
}
</style>
