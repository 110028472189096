<template>
  <div
    class="action"
    :class="{
      'action-selected': selected,
      'multi-select-cursor-allowed': shiftHover && !readOnly,
      'multi-select-cursor-not-allowed': shiftHover && readOnly,
      'grab-mode': grabMode
    }"
    :style="actionStyle"
    :data-action-id="action.id"
    @click="onClick"
    @mousedown="onMouseDown"
    @mouseover="onMouseOver"
    @mousemove="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <div style="display: flex; flex-direction: column; gap: 5px">
      <action-new-ui
        :grab-mode="grabMode"
        :style="selectedActionStyles"
        :action="action"
        :errored="errored"
        @show-reporting="showReporting"
      />
      <div class="action-options-ct">
        <div class="action-options">
          <template v-for="key in Object.keys(action.successors)">
            <div
              :style="{ width: `calc(100%/${getSuccessorCount()})` }"
              class="action-option-multidecision-stat"
            >
              <div>
                {{ formatStats(action.stats['MultiDecision:' + key]) }}
              </div>
            </div>
          </template>
        </div>
        <div class="action-options">
          <template v-for="key in Object.keys(action.successors)">
            <div
              v-tooltip="action.config.successorTitles[key]"
              :style="{ width: `calc(100%/${getSuccessorCount()})` }"
              class="action-option-multidecision"
            >
              {{ formatKey(key) }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <action-controls
      v-if="!dragging"
      @mouseover="inControls = true"
      @mouseleave="inControls = false"
      :show-controls="showControls"
      :read-only="readOnly"
      :workflow="workflow"
      :action="action"
      :grab-mode="grabMode"
      :selected-actions="selectedActions"
    />
  </div>
</template>
<script>
import Action from './AbstractAction'
import { ANCHORS } from '@/views/WorkflowEdit/Actions/MultiDecisionActionAnchors'
import { SUCCESSOR_OFFSET } from '@/views/WorkflowEdit/Actions/MultiDecisionActionSuccessorOffset'
import ActionControls from '@/views/WorkflowEdit/Actions/components/ActionControls.vue'

export default {
  components: { ActionControls },

  extends: Action,

  inject: ['eventBus'],

  data() {
    return {
      icon: 'question-mark'
    }
  },

  watch: {
    'action.successors': {
      handler() {
        if (
          this.getSuccessorCount() !==
          this.jsPlumb.getEndpoints(this.$el).length - 1
        ) {
          this.updateEndpoints()
          this.redrawAnchors()
        }
        this.$nextTick(() => {
          this.drawLines()
        })
      }
    }
  },

  created() {
    this.$options.successorAnchors = this.getAnchors()
    this.$options.successorOffset = this.getSuccessorOffset()
    this.formatter = Intl.NumberFormat('en', { notation: 'compact' })
    this.eventBus.$on('multidecision-endpoint-update', (id) =>
      this.updateAfterGroupMove(id)
    )
  },

  methods: {
    formatKey(key) {
      if (key === 'else') {
        return this.$t('multiDecisionElse')
      }

      return key
    },
    updateAfterGroupMove(id) {
      if (id === this.action.id) {
        this.updateEndpoints()
        this.redrawAnchors()
        this.$nextTick(() => {
          this.drawLines()
        })
      }
    },
    onConnection(info) {
      const sourceId = info.sourceEndpoint.getParameter('actionId'),
        successorName = info.sourceEndpoint.getParameter('successorName'),
        targetId = info.targetEndpoint.getParameter('actionId')
      if (
        this.action.id === sourceId &&
        targetId &&
        this.action.successors[successorName] !== targetId
      ) {
        const successors = Object.assign({}, this.action.successors)
        successors[successorName] = targetId
        this.workflowApi.updateAction(sourceId, { successors: successors })
      }
    },

    onConnectionDetached(info) {
      const sourceId = info.sourceEndpoint.getParameter('actionId'),
        successorName = info.sourceEndpoint.getParameter('successorName')
      if (
        this.action.id === sourceId &&
        this.action.successors.hasOwnProperty(successorName) &&
        this.action.successors[successorName] !== ''
      ) {
        const successors = Object.assign({}, this.action.successors)
        successors[successorName] = ''
        this.workflowApi.updateAction(sourceId, { successors: successors })
      }
    },

    updateEndpoints() {
      const successorCount = this.getSuccessorCount()
      let successorsBackup = {}

      // backup the successors
      for (const [successorKey, value] of Object.entries(
        this.action.successors
      )) {
        successorsBackup[successorKey] = value
      }

      const sessionStorageKey = this.action.id + '-successors'
      if (!sessionStorage.getItem(sessionStorageKey)) {
        sessionStorage.setItem(
          sessionStorageKey,
          JSON.stringify(successorsBackup)
        )
      } else {
        successorsBackup = JSON.parse(sessionStorage.getItem(sessionStorageKey))
      }

      // delete the endpoints
      const endpoints = this.jsPlumb.getEndpoints(this.$el)
      for (let i = 1; i <= successorCount + 1; i++) {
        this.jsPlumb.deleteEndpoint(endpoints[i], true)
      }

      // re-add the endpoints
      for (const [successorKey, value] of Object.entries(successorsBackup)) {
        this.renderOutput(successorKey)
        this.action.successors[successorKey] = value
      }
    },

    redrawAnchors() {
      const anchors = this.getAnchors()
      const anchorKeys = Object.keys(anchors)
      this.$options.successorAnchors = anchors
      this.$options.successorOffset = this.getSuccessorOffset()

      // reposition existing anchors from index 1
      this.jsPlumb.getEndpoints(this.$el).forEach((endpoint, index) => {
        if (index === 0) {
          return
        }

        endpoint.setAnchor(anchors[anchorKeys[index - 1]])
      })

      this.$nextTick(() => this.jsPlumb.repaintEverything())
    },

    getAnchors() {
      return ANCHORS[this.getSuccessorCount()]
    },

    getSuccessorOffset() {
      return SUCCESSOR_OFFSET[this.getSuccessorCount()]
    },

    getSuccessorCount() {
      return Object.keys(this.action.successors).length
    },

    formatStats(stat) {
      if (!stat) {
        return 0
      }

      return this.formatter.format(stat)
    }
  }
}
</script>
<style scoped lang="sass">
.action
  display: flex

.action-option-multidecision
  color: #ffffff
  background-color: #1e3a8a
  box-sizing: border-box
  float: left
  padding: 12px 0
  text-transform: uppercase
  text-align: center
  font-weight: 600
  border-left: 1px solid var(--teal-400, #41c5b7)
  border-right: 1px solid var(--teal-400, #41c5b7)
  border-bottom: 1px solid var(--teal-400, #41c5b7)
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px

.action-option-multidecision-stat
    color: #707070
    background-color: #ffffff
    box-sizing: border-box
    float: left
    padding: 6px 0 5px 0
    text-transform: uppercase
    text-align: center
    border: 1px solid var(--teal-400, #41c5b7)
    border-top-left-radius: 4px
    border-top-right-radius: 4px

.action-overlay
  border-radius: 0 5px 0 0

.action-options-ct
  display: flex
  flex-direction: column

.action-options
  display: flex
  gap: 5px
</style>
