import i18n from '@/config/i18n/index'

const ActionDefaults = {
  Email: {
    successors: {
      primary: ''
    },
    config: {
      isActionMultiLanguage: true,
      defaultLang: 'en',
      languages: ['en'],
      ignore_subscription: 'doNotIgnore',
      consent_preference: {
        enabled: false,
        preference: null
      },
      exclusion: {
        check: false,
        category: null
      },
      messages: {
        en: {
          subject: '',
          fromName: '',
          fromAddress: '',
          replyToName: '',
          replyToAddress: '',
          analyticsCode: '',
          contentId: 0,
          attachments: []
        }
      }
    }
  },
  Sms: {
    successors: {
      primary: ''
    },
    config: {
      defaultLang: 'en',
      languages: ['en'],
      messages: {
        en: {
          fromName: '',
          text: '',
          optOutMessage: ''
        }
      },
      include_unsubscribe: false,
      include_opt_out_reply: false,
      reply_to_messages: [],
      ignore_subscription: 'default',
      consent_preference: {
        enabled: false,
        preference: null
      },
      exclusion: {
        check: false,
        category: null
      },
      voucher_code_enabled: 0,
      voucher_code_position: 1
    }
  },
  Push: {
    successors: {
      primary: ''
    },
    config: {
      defaultLang: 'en',
      languages: ['en'],
      exclusion: {
        check: false,
        category: null
      },
      voucher_code_enabled: 0,
      voucher_code_position: 1
    }
  },
  IosPush: {
    successors: {
      primary: ''
    },
    config: {
      defaultLang: 'en',
      languages: ['en'],
      exclusion: {
        check: false,
        category: null
      },
      subType: 'IosPush',
      ios: {
        messages: {
          en: {
            title: '',
            text: ''
          }
        },
        payload: [],
        pictureUrl: '',
        defaultAction: {
          type: 'none',
          value: ''
        },
        ignore_subscription: 'default',
        consent_preference: {
          enabled: false,
          preference: null
        },
        voucher_code_enabled: 0,
        voucher_code_position: 1
      },
      android: null,
      web: null,
      voucher_code_enabled: 0,
      voucher_code_position: 1
    }
  },
  AndroidPush: {
    successors: {
      primary: ''
    },
    config: {
      defaultLang: 'en',
      languages: ['en'],
      exclusion: {
        check: false,
        category: null
      },
      subType: 'AndroidPush',
      ios: null,
      android: {
        messages: {
          en: {
            title: '',
            text: ''
          }
        },
        payload: [],
        pictureUrl: '',
        defaultAction: {
          type: 'none',
          value: ''
        },
        ignore_subscription: 'default',
        consent_preference: {
          enabled: false,
          preference: null
        },
        voucher_code_enabled: 0,
        voucher_code_position: 1
      },
      web: null,
      voucher_code_enabled: 0,
      voucher_code_position: 1
    }
  },
  WebPush: {
    successors: {
      primary: ''
    },
    config: {
      defaultLang: 'en',
      languages: ['en'],
      exclusion: {
        check: false,
        category: null
      },
      subType: 'WebPush',
      ios: null,
      android: null,
      web: {
        messages: {
          en: {
            title: '',
            text: ''
          }
        },
        pictureUrl: '',
        iconUrl: '',
        url: '',
        ignore_subscription: 'default',
        consent_preference: {
          enabled: false,
          preference: null
        },
        voucher_code_enabled: 0,
        voucher_code_position: 1
      },
      voucher_code_enabled: 0,
      voucher_code_position: 1
    }
  },
  Inbox: {
    successors: {
      primary: ''
    },
    config: {
      exclusion: {
        check: false,
        category: null
      },
      defaultLang: 'en',
      languages: ['en'],
      messages: {
        en: {
          push_title: '',
          push_text: ''
        }
      },
      push_action: {
        action: '',
        url: '',
        deeplink: '',
        url_blank: false
      },
      push_icon: null,
      payload_add: [],
      inbox_style: {
        bg: '',
        title_bg: ''
      },
      voucher_code_enabled: 0,
      voucher_code_position: 1
    }
  },
  Webhook: {
    successors: {
      primary: ''
    },
    config: {
      defaultLang: 'en',
      languages: ['en'],
      messages: {
        en: {
          url: '',
          request_body_data: {
            post: [],
            raw: '',
            json: []
          },
          get_params: [],
          request_headers: [],
          request_body_type: 'raw'
        }
      }
    }
  },
  Event: {
    successors: {
      primary: ''
    },
    config: {
      event_title: '',
      event_type: '',
      event_value: '',
      use_json: false,
      json: []
    }
  },
  Delay: {
    successors: {
      primary: ''
    },
    options: {
      type: 'timePeriod',
      interval: ''
    }
  },
  Decision: {
    successors: {
      yes: '',
      no: ''
    },
    validatorType: 'allOf',
    validator: []
  },
  Split: {
    successors: {
      a: '',
      b: ''
    }
  },
  MultiDecision: {
    successors: {
      a: '',
      else: ''
    },
    validators: {
      a: {
        validatorType: 'allOf',
        validator: [
          [
            {
              type: 'profile',
              field: '',
              condition: 'TextEquals',
              negator: false,
              conditionArgs: {
                value: ''
              }
            }
          ]
        ]
      },
      b: {
        validatorType: 'allOf',
        validator: []
      },
      c: {
        validatorType: 'allOf',
        validator: []
      },
      d: {
        validatorType: 'allOf',
        validator: []
      },
      e: {
        validatorType: 'allOf',
        validator: []
      }
    },
    config: {
      successorTitles: {
        a: i18n.t('untitledLabel')
      }
    }
  },
  AdRetarget: {
    successors: {
      primary: ''
    },
    external_audience_id: 0,
    event: 0
  },
  Promotion: {
    successors: {
      primary: ''
    },
    promotion_id: '',
    event: 0,
    promotion_amount: 0,
    promotion_amount_attribute_title: null,
    promotion_amount_required: 0,
    promotion_duration: 1,
    promotion_duration_required: 0
  },
  End: {
    successors: {}
  }
}

Object.freeze(ActionDefaults)

export default ActionDefaults
