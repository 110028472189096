<template>
  <div>
    <TabView ref="tabs" :key="getSuccessorCount()" :active-index="activeIndex">
      <TabPanel
        v-if="hasSuccessor('a')"
        :header="`A - ${action.config.successorTitles['a']}`"
      >
        <decision
          :key="decisionKey"
          @remove-decision-group="updateDecisionsKey()"
          ref="a"
          :action="action"
          :read-only="readOnly"
          :workflow="workflow"
          successor-key="a"
          @add-decision-group="addDecisionGroup"
          @new-active-index="activeIndex = $event"
          @move-left="activeIndex = activeIndex - 1"
          @move-right="activeIndex = activeIndex + 1"
        />
      </TabPanel>
      <TabPanel
        v-if="hasSuccessor('b')"
        :header="`B - ${action.config.successorTitles['b']}`"
      >
        <decision
          :key="decisionKey"
          @remove-decision-group="updateDecisionsKey()"
          ref="b"
          :action="action"
          :read-only="readOnly"
          :workflow="workflow"
          successor-key="b"
          @add-decision-group="addDecisionGroup"
          @new-active-index="activeIndex = $event"
          @move-left="activeIndex = activeIndex - 1"
          @move-right="activeIndex = activeIndex + 1"
        />
      </TabPanel>
      <TabPanel
        v-if="hasSuccessor('c')"
        :header="`C - ${action.config.successorTitles['c']}`"
      >
        <decision
          :key="decisionKey"
          @remove-decision-group="updateDecisionsKey()"
          ref="c"
          :action="action"
          :read-only="readOnly"
          :workflow="workflow"
          successor-key="c"
          @add-decision-group="addDecisionGroup"
          @new-active-index="activeIndex = $event"
          @move-left="activeIndex = activeIndex - 1"
          @move-right="activeIndex = activeIndex + 1"
        />
      </TabPanel>
      <TabPanel
        v-if="hasSuccessor('d')"
        :header="`D - ${action.config.successorTitles['d']}`"
      >
        <decision
          :key="decisionKey"
          @remove-decision-group="updateDecisionsKey()"
          ref="d"
          :action="action"
          :read-only="readOnly"
          :workflow="workflow"
          successor-key="d"
          @add-decision-group="addDecisionGroup"
          @new-active-index="activeIndex = $event"
          @move-left="activeIndex = activeIndex - 1"
          @move-right="activeIndex = activeIndex + 1"
        />
      </TabPanel>
      <TabPanel
        v-if="hasSuccessor('e')"
        :header="`E - ${action.config.successorTitles['e']}`"
      >
        <decision
          :key="decisionKey"
          @remove-decision-group="updateDecisionsKey()"
          ref="e"
          :action="action"
          :read-only="readOnly"
          :workflow="workflow"
          successor-key="e"
          @add-decision-group="addDecisionGroup"
          @new-active-index="activeIndex = $event"
          @move-left="activeIndex = activeIndex - 1"
          @move-right="activeIndex = activeIndex + 1"
        />
      </TabPanel>
    </TabView>
  </div>
</template>
<script>
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Decision from '@/views/WorkflowEdit/ToolboxesLarge/MultiDecision/Decision'
import vClickOutside from 'v-click-outside'
import moment from 'moment/moment'

export default {
  components: {
    Decision,
    TabView,
    TabPanel
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  inject: ['workflowApi', 'contentApi', 'eventBus'],

  props: ['action', 'workflow', 'readOnly'],

  data() {
    return {
      successorCount: this.getSuccessorCount(),
      isLoading: false,
      decisionKey: moment().toDate().getTime(),
      activeIndex: 0
    }
  },

  watch: {
    'action.successors': {
      handler() {
        this.successorCount = this.getSuccessorCount()
      }
    }
  },

  created() {
    this.eventBus.$on('multidecision-endpoint-update-finish', (id) => {
      if (id === this.action.id) {
        this.isLoading = false
        sessionStorage.removeItem(this.action.id + '-successors')
      }
    })

    this.eventBus.$on('multidecision-successor-change-disable-all', (id) => {
      if (id === this.action.id) {
        this.isLoading = true
      }
    })
  },

  beforeDestroy() {
    this.workflowApi.updateAction(this.action.id, {
      validators: this.action.validators,
      config: this.action.config
    })
  },

  methods: {
    hasSuccessor(key) {
      return this.action.successors.hasOwnProperty(key)
    },

    updateDecisionsKey() {
      this.decisionKey = moment().toDate().getTime()
    },

    getSuccessorCount() {
      return Object.keys(this.action.successors).length
    },

    addDecisionGroup() {
      this.updateDecisionsKey()
      if (this.successorCount > 5) {
        return
      }

      if (!this.action.successors) {
        return
      }

      if (this.isLoading) {
        return
      }

      this.eventBus.$emit(
        'multidecision-successor-change-disable-all',
        this.action.id
      )

      let key = 'a'
      let lastKey = 'a'
      let titles = {}
      const successorsMap = {}

      // get last successor
      const elseSuccessor = this.action.successors.else

      for (let i = 0; i < Math.min(this.successorCount, 6); i++) {
        successorsMap[key] = this.action.successors[key] || ''
        titles[key] = this.action.config.successorTitles[key] || 'Untitled'
        lastKey = key
        key = String.fromCharCode(key.charCodeAt(0) + 1)
      }

      successorsMap.else = elseSuccessor

      this.workflowApi
        .updateAction(this.action.id, {
          successors: successorsMap,
          config: { successorTitles: titles }
        })
        .then(() => {
          this.activeIndex = lastKey.charCodeAt(0) - 97
        })
      this.eventBus.$emit('multidecision-endpoint-update', this.action.id)
    }
  }
}
</script>
<style></style>
