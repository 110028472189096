<template>
  <div
    class="action"
    :class="{
      'action-selected': selected,
      'multi-select-cursor-allowed': shiftHover && !readOnly,
      'multi-select-cursor-not-allowed': shiftHover && readOnly,
      'grab-mode': grabMode
    }"
    :style="actionStyle"
    :data-action-id="action.id"
    @click="onClick"
    @mousedown="onMouseDown"
    @mouseover="onMouseOver"
    @mousemove="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <div style="display: flex; flex-direction: column; gap: 5px">
      <action-new-ui
        :grab-mode="grabMode"
        :style="selectedActionStyles"
        :action="action"
        :errored="errored"
        @show-reporting="showReporting"
      />
      <div class="action-options">
        <template v-for="key in Object.keys(action.successors)">
          <div
            :key="key"
            :style="{ width: `calc(100%/${getSuccessorCount()})` }"
            class="action-option-split"
          >
            {{ key }}
          </div>
        </template>
      </div>
    </div>
    <action-controls
      v-if="!dragging"
      @mouseover="inControls = true"
      @mouseleave="inControls = false"
      :show-controls="showControls"
      :read-only="readOnly"
      :workflow="workflow"
      :action="action"
      :grab-mode="grabMode"
      :selected-actions="selectedActions"
    />
  </div>
</template>
<script>
import Action from './AbstractAction'
import { ANCHORS } from '@/views/WorkflowEdit/Actions/SplitActionAnchors'
import { SUCCESSOR_OFFSET } from '@/views/WorkflowEdit/Actions/SplitActionSuccessorOffset'

export default {
  extends: Action,

  data() {
    return {
      icon: 'question-mark'
    }
  },

  watch: {
    'action.successors': {
      handler() {
        if (
          this.getSuccessorCount() !==
          this.jsPlumb.getEndpoints(this.$el).length - 1
        ) {
          this.updateEndpoints()
          this.redrawAnchors()
        }
        this.$nextTick(() => {
          this.drawLines()
        })
      }
    }
  },

  created() {
    this.$options.successorAnchors = this.getAnchors()
    this.$options.successorOffset = this.getSuccessorOffset()
  },

  methods: {
    onConnection(info) {
      const sourceId = info.sourceEndpoint.getParameter('actionId'),
        successorName = info.sourceEndpoint.getParameter('successorName'),
        targetId = info.targetEndpoint.getParameter('actionId')
      if (
        this.action.id === sourceId &&
        targetId &&
        this.action.successors[successorName] !== targetId
      ) {
        const successors = Object.assign({}, this.action.successors)
        successors[successorName] = targetId
        this.workflowApi.updateAction(sourceId, { successors: successors })
      }
    },

    onConnectionDetached(info) {
      const sourceId = info.sourceEndpoint.getParameter('actionId'),
        successorName = info.sourceEndpoint.getParameter('successorName')
      if (
        this.action.id === sourceId &&
        this.action.successors.hasOwnProperty(successorName) &&
        this.action.successors[successorName] !== ''
      ) {
        const successors = Object.assign({}, this.action.successors)
        successors[successorName] = ''
        this.workflowApi.updateAction(sourceId, { successors: successors })
      }
    },

    updateEndpoints() {
      const anchors = this.getAnchors()
      const anchorKeys = Object.keys(anchors)
      const successorCount = this.getSuccessorCount()

      // add new endpoints
      while (successorCount > this.jsPlumb.getEndpoints(this.$el).length - 1) {
        this.renderOutput(
          anchorKeys[this.jsPlumb.getEndpoints(this.$el).length - 1]
        )
      }

      // remove endpoints
      while (successorCount < this.jsPlumb.getEndpoints(this.$el).length - 1) {
        const endpoint = this.jsPlumb.getEndpoints(this.$el)
        this.jsPlumb.deleteEndpoint(endpoint[endpoint.length - 1], true)
      }
    },

    redrawAnchors() {
      const anchors = this.getAnchors()
      const anchorKeys = Object.keys(anchors)
      this.$options.successorAnchors = anchors
      this.$options.successorOffset = this.getSuccessorOffset()

      // reposition existing anchors from index 1
      this.jsPlumb.getEndpoints(this.$el).forEach((endpoint, index) => {
        if (index === 0) {
          return
        }

        endpoint.setAnchor(anchors[anchorKeys[index - 1]])
      })

      this.$nextTick(() => this.jsPlumb.repaintEverything())
    },

    getAnchors() {
      return ANCHORS[this.getSuccessorCount()]
    },

    getSuccessorOffset() {
      return SUCCESSOR_OFFSET[this.getSuccessorCount()]
    },

    getSuccessorCount() {
      return Object.keys(this.action.successors).length
    }
  }
}
</script>
<style scoped lang="sass">
.action
  display: flex

.action-option-split
  color: #ffffff
  background-color: #1e3a8a
  box-sizing: border-box
  float: left
  padding: 12px 0
  text-transform: uppercase
  text-align: center
  display: flex
  justify-content: center
  align-items: center
  gap: 5px
  border: 1px solid var(--teal-400, #41c5b7)
  border-radius: 4px

.action-options
  display: flex
  gap: 5px

.action-overlay
  border-radius: 0 5px 0 0
</style>
