<template>
  <div class="workflow-edit-navbar">
    <div class="navbar-left">
      <template v-if="!isPreview && isFramed">
        <el-button
          @click="saveAndClose"
          :disabled="closing"
          icon="el-icon-arrow-left"
        >
          {{ $t('navbarClose') }}
        </el-button>
        <div class="divider"></div>
      </template>
    </div>

    <div class="workflow-history" v-if="!isPreview && !workflow.isSnapshot">
      <div class="workflow-save-status">
        <span v-if="status.saving">{{ $t('workflowNavSaving') }}</span>
        <span v-else>{{ $t('workflowChangesSaved') }}</span>
      </div>
      <button
        class="history-btns"
        :disabled="canUndo"
        @click="workflowApi.undo()"
        style="width: 28px; height: 28px"
      >
        <img src="@/images/undo.png" style="width: 20px; height: 20px" />
      </button>
      <button
        class="history-btns"
        :disabled="canRedo"
        @click="workflowApi.redo()"
        style="width: 28px; height: 28px"
      >
        <img src="@/images/redo.png" style="width: 20px; height: 20px" />
      </button>
      <div class="divider"></div>
    </div>

    <div class="workflow-status">
      <el-dropdown placement="bottom-start" size="small" trigger="click">
        <div class="status-button">
          <el-badge :type="badgeType" :value="status.messages.length">
            <el-button icon="el-icon-warning-outline" />
          </el-badge>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="dropdown-items">
            <el-dropdown-item v-if="!status.messages.length">
              <a>No errors</a>
            </el-dropdown-item>
            <el-dropdown-item
              v-for="(message, index) in status.messages"
              :key="index"
              class="message"
            >
              <a @click="focusAction(message.id)">
                <div class="message-icon" :class="messageClass(message)"></div>
                <el-tooltip
                  popper-class="error-msg-tooltip"
                  :disabled="message.desc.length < 40"
                >
                  <template #content>
                    <div>{{ $t(message.desc) }}</div>
                  </template>
                  <span class="message-text">{{ $t(message.desc) }}</span>
                </el-tooltip>
                <button
                  v-if="message.category === 'SEND-CREDITS'"
                  class="btn btn-xs btn-primary"
                  @click="actionUpgrade"
                >
                  Upgrade
                </button>
              </a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <el-button
      type="primary"
      v-if="!workflow.isSnapshot"
      @click="eventBus.$emit('showWorkflowReporting', true)"
    >
      <svg><use xlink:href="#chart"></use></svg>
      {{ $t('navbarAnalytics') }}
    </el-button>

    <template v-if="!isPreview && !workflow.isSnapshot">
      <div class="divider"></div>
      <online-users :members="members"></online-users>
    </template>
  </div>
</template>
<script>
import OnlineUsers from './OnlineUsers'
import alert from '@/libs/Alert'
import config from '@/config/config'
import ElButton from 'element-ui/packages/button'
import ElBadge from 'element-ui/packages/badge'
import '@/svg/chart.svg'

export default {
  props: ['workflow', 'status', 'history', 'members', 'isPreview', 'selectedActions'],

  inject: ['workflowApi', 'eventBus'],

  components: {
    OnlineUsers,
    ElButton,
    ElBadge
  },

  data() {
    return {
      toolboxOpen: false,
      actionId: null,
      closing: false
    }
  },

  created() {
    this.eventBus.$on('largeToolboxOpen', (value) => (this.toolboxOpen = value))
    this.eventBus.$on(
      'multidecision-successor-change-disable-all',
      (actionId) => (this.actionId = actionId)
    )
  },

  computed: {
    badgeType: function () {
      if (this.errors.length) {
        return 'danger'
      } else if (this.warnings.length) {
        return 'warning'
      }

      return 'success'
    },

    errors: function () {
      return this.status.messages.filter((message) => message.type === 'error')
    },

    warnings: function () {
      return this.status.messages.filter(
        (message) => message.type === 'warning'
      )
    },

    isFramed() {
      return window.top !== window.self
    },

    canUndo() {
      return (
        this.workflow.isRunning ||
        this.status.saving ||
        !this.history.hasUndo ||
        this.toolboxOpen
      )
    },

    canRedo() {
      return (
        this.workflow.isRunning ||
        this.status.saving ||
        !this.history.hasRedo ||
        this.toolboxOpen
      )
    }
  },

  methods: {
    actionUpgrade() {
      alert.show({
        title: 'Upgrade Account',
        htmlMessage: true,
        message: `To upgrade your account please contact us on <a href='tel:+441327811884'>+441327811884</a>.`
      })
    },

    messageClass(message) {
      const messageClass = {
        error: 'btn-danger',
        warning: 'btn-warning',
        ok: 'btn-success'
      }

      return messageClass[message.type]
    },

    focusAction(actionId) {
      this.eventBus.$emit('selectAction', actionId)
    },

    saveAndClose() {
      this.closing = true
      this.selectedActions.deselect()
      setInterval(() => {
        if (this.status.saving) {
          return
        }
        this.sendClose()
      }, 200)
    },

    sendClose() {
      if (window.self !== window.top) {
        window.parent.postMessage({ type: 'mxaClose' }, config.xpBaseUrl)
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.dropdown-items
    li:hover
        color: #636363
        background-color: #f5f5f5
    li a
        color: #707070
    li a:hover
        text-decoration: none

.el-button
    height: 42px
    font-weight: 900
    color: #2563EB
    svg
      height: 14px
      width: 14px
      margin-right: 3px

.workflow-edit-navbar
    z-index: 5
    position: fixed
    width: 100%
    top: 0px
    background-color: #1E40AF
    height: 64px
    display: flex
    align-items: center
    justify-content: flex-end
    padding-right: 22px
    padding-left: 22px
    .el-button--primary
        font-weight: 900
        background-color: #1E40AF
        border-color: #FFFFFF
        color: #FFFFFF
    .el-button--primary:hover
        background: #66b1ff

    .divider
        width: 0px
        border-left: 1px solid #ccc
        height: 65%
        margin: 0 15px

    .workflow-history
        height: 100%
        display: flex
        align-items: center

        .workflow-save-status
          color: #FFFFFF

        button
          display: flex
          justify-content: center
          align-items: center
          cursor: pointer
          margin-left: 10px

        .history-btns
          background-color: #ffffff
          border: 1px solid
          border-radius: 6px

    .workflow-status
        margin-right: 14px
        .btn-status
            transition: background-color .3s ease
            border: 1px solid #c3c3c3

        .loading
            color: transparent

        .el-button
            width: 42px
            padding: 0
            ::v-deep i
              font-size: 21px
              width: 21px
              font-weight: 900

        ::v-deep .el-badge__content
          border: none

    .navbar-left
      flex-grow: 1
      height: 64px
      display: flex
      align-items: center
      justify-content: flex-start

.message
    cursor: pointer
    max-width: 305px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    .message-icon
        width: 12px
        height: 12px
        display: inline-block
        border-radius: 50%
        margin-right: 5px

    .message-text
        margin-right: 30px
</style>
<style>
.error-msg-tooltip {
  max-width: 300px;
}
</style>
